<template>
  <div class="sld_permission_info">
    <!-- <MemberTitle :memberTitle="L['授信申请']"></MemberTitle> -->
    <div class="container">
      <h3 @click="nextStep('index')">{{ L["授信申请"] }}</h3>
      <template v-if="memberInfo.memberType == 3">
        <template v-if="step == 1">
          <div class="main">
            <div class="box2 flex-row">
              <span class="word23" :class="{ active: tabIndex == 0 }" @click="changeTab(0)">授信申请</span>
              <span class="word23" :class="{ active: tabIndex == 1 }" @click="changeTab(1)">消费记录</span>
              <span class="word23" :class="{ active: tabIndex == 2 }" @click="changeTab(2)">申请记录</span>
            </div>
            <template v-if="tabIndex == 0">
              <span class="word27">可申请企业</span>
              <div class="bd2_box">
                <template v-for="(item,index) in conmpany.data" :key="item.storeId">
                  <div class="bd2 flex-col">
                    <div class="bd3 flex-col">
                      <div class="bd3_bg" v-if="item.storeLogo" :style="`background-image:url('`+item.storeLogo+`')`">
                      </div>
                      <i v-else class="iconfont icontouxiang iconImg"></i>
                    </div>
                    <span class="info9">{{item.storeName}}</span>
                    <span class="info10" v-if="item.state == 3">授信额度：{{item.creditAmount}}</span>
                    <span class="info10" v-if="item.state == 3">剩余可用额度：{{item.remainAmount}}</span>
                    <span class="info10"
                      v-if="item.state == 3">账期：{{item.creditPeriod?item.creditPeriod+'天':'--'}}</span>
                    <div class="bd4 flex-col" v-if="item.state != 3"></div>
                    <!-- state 1==申请授信，2==审核中，3==授信记录，4==审核拒绝，5==禁用 -->
                    <button v-if="item.state == 1" class="flex-col bd5" @click="apply(index,item.storeId,1)"><span
                        class="txt17">{{item.stateValue}}</span></button>
                    <button v-else-if="item.state == 2" class="flex-col bd7"><span class="txt17">审核中</span></button>
                    <button v-else-if="item.state == 3" class="flex-col bd6" @click="nextStep(item.storeId)"><span
                        class="txt17">{{item.stateValue}}</span></button>
                    <button v-else-if="item.state == 4" class="flex-col bd8" @click="apply(index,item.storeId,1)"><span
                        class="txt17">申请授信</span></button>
                    <button v-else class="flex-col bd7"><span class="txt17">{{item.stateValue}}</span></button>
                    <span class="txt177" v-if="item.state == 4">申请失败,您可以查看申请记录或重新申请。</span>
                  </div>
                </template>
              </div>
              <el-pagination @current-change="handleCurrentChange1" :currentPage="pageData.current"
                :page-size="pageData.pageSize" layout="prev, pager, next, jumper" :total="pageData.total"
                :hide-on-single-page="false" class="flex_row_end_center"></el-pagination>
              <SldCommonEmpty v-show="conmpany.data.length == 0" tip="暂无可申请企业" totalWidth="934px" />
            </template>
            <template v-else-if="tabIndex == 1">
              <div class="middle">
                <div class="history">
                  <template v-for="(item,index) in storeList.data" :key="item.storeId">
                    <div class="history_item" :class="{active:historyIndex == index}"
                      @click="changeHisIndex(index,item.storeId)">
                      {{item.storeName}}
                    </div>
                  </template>
                </div>
                <div class="main2 flex-row">
                  <div class="mod1 flex-col">
                    <span class="word28">授信额度</span>
                    <span class="word29">¥{{storeList.creditAmount}}</span>
                  </div>
                  <div class="mod2 flex-col"></div>
                  <div class="mod3 flex-col">
                    <span class="txt17">授信可用额度</span>
                    <span class="info9">¥{{storeList.remainAmount}}</span>
                  </div>
                </div>
                <template v-for="item in storeList.list" :key="item">
                  <div class="main4 flex-row">
                    <span class="word30">{{item.logTime}}</span>
                    <div class="mod4">
                      <span class="txt18">{{item.type==1?'-':'+'}}¥{{item.changeAmount}}</span>
                    </div>
                    <span class="word31">{{item.typeValue}}</span>
                    <button class="mod5 flex-col" @click="navTo(item)">
                      <span class="info11">查看</span>
                    </button>
                  </div>
                </template>
                <el-pagination @current-change="handleCurrentChange2" :currentPage="pageData.current"
                  :page-size="pageData.pageSize" layout="prev, pager, next, jumper" :total="pageData.total"
                  :hide-on-single-page="false" class="flex_row_end_center" v-if="storeList.list.length > 0"
                  style="margin-right:20px;margin-bottom:12px;"></el-pagination>
              </div>
              <SldCommonEmpty v-show="storeList.list.length == 0" tip="暂无消费记录" totalWidth="934px" />
            </template>
            <template v-else>
              <div class="apply">
                <div class="mod7 flex-col">
                  <div class="layer4 flex-row">
                    <span class="word29">申请企业</span>
                    <span class="word29 info10">申请时间</span>
                    <span class="word29 info11">审核状态</span>
                    <span class="word29 info12">授权信息</span>
                    <span class="word29 word30">备注</span>
                    <span class="word29 info13">操作</span>
                  </div>
                </div>
                <template v-for="(item,index) in applyList.data" :key="item.creditId">
                  <div class="mod8 flex-col">
                    <div class="section3 flex-row">
                      <span class="word31">{{item.storeName}}</span>
                      <span class="word31 word32">{{item.createTime}}</span>
                      <span class="word31 word33">{{item.stateValue}}</span>
                      <span class="word31 word34">
                        <span>{{item.creditAmount ? '授信额度：¥'+item.creditAmount : '--'}}</span>
                        <span v-if="item.creditAmount">账期：{{item.creditPeriod}}天</span>
                      </span>
                      <span class="word31 word35">{{item.state==1 ? '--' :
                        item.refuseReason ? item.refuseReason : '--'}}</span>
                      <span class="word31 word36" :style="item.state==3?'cursor:pointer':''"
                        @click="apply(index,item.storeId,2)">{{item.state==3 ? '重新审核' : '--'}}</span>
                    </div>
                  </div>
                </template>
                <el-pagination @current-change="handleCurrentChange3" :currentPage="pageData.current"
                  :page-size="pageData.pageSize" layout="prev, pager, next, jumper" :total="pageData.total"
                  :hide-on-single-page="false" class="flex_row_end_center" v-if="applyList.data.length > 0"
                  style="margin-top:10px;"></el-pagination>
              </div>
              <SldCommonEmpty v-show="applyList.data.length == 0" tip="暂无申请记录" totalWidth="934px" />
            </template>
          </div>
        </template>
        <template v-else-if="step == 2">
          <div class="auth_info">
            <div class="box2 flex-col">
              <div class="bd8 flex-row">
                <span class="info14">授信企业</span>
                <span class="word20">授权信息</span>
                <span class="info15">更新时间</span>
                <span class="info16">备注</span>
              </div>
            </div>
            <template v-for="item in autoStoreList.data" :key="item">
              <div class="box3 flex-col">
                <div class="mod29 flex-row">
                  <span class="txt16">{{item.storeName}}</span>
                  <div class="box4 flex-col">
                    <span class="word21">授信额度：¥{{item.creditAmount}}</span>
                    <span class="info17">账期：{{item.creditPeriod}}天</span>
                  </div>
                  <span class="info18">{{item.createTime}}</span>
                  <span class="word22">{{item.remark ? item.remark : '--'}}</span>
                </div>
              </div>
            </template>
            <el-pagination @current-change="handleCurrentChange4" :currentPage="pageData.current"
              :page-size="pageData.pageSize" layout="prev, pager, next, jumper" :total="pageData.total"
              :hide-on-single-page="false" class="flex_row_end_center" v-if="autoStoreList.data.length > 0"
              style="margin-top:10px;"></el-pagination>
          </div>
        </template>
      </template>
      <template v-else>
        <div class="section2 flex-col">
          <div class="section3 flex-col">
            <div class="main5 flex-col">
              <img class="pic1" referrerpolicy="no-referrer" :src="attestation" />
            </div>
            <span class="info13">您尚未认证成为企业用户，认证后即可享受信用支付服务。</span>
            <div class="main6 flex-col" @click="toAuth">
              <span class="word23">去认证</span>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  import { reactive, ref, getCurrentInstance, onMounted } from "vue";
  import { useRoute, useRouter } from 'vue-router';
  import { useStore } from 'vuex';
  import MemberTitle from "@/components/MemberTitle";
  import { ElMessage } from 'element-plus';
  import SldCommonEmpty from '@/components/SldCommonEmpty';
  export default {
    name: "FinacePermission",
    components: {
      MemberTitle,
      SldCommonEmpty
    },
    setup() {
      const { proxy } = getCurrentInstance();
      const L = proxy.$getCurLanguage();
      const router = useRouter();
      const route = useRoute();
      const store = useStore();
      const memberInfo = ref(store.state.memberInfo);
      const attestation = require('@/assets/persen_attestation.png');
      const step = ref(1);
      const tabIndex = ref(0);
      const conmpany = reactive({ data: [] });
      const historyIndex = ref(0);
      const storeList = reactive({ //消费纪录列表数据
        data: [],
        list: [],
        creditAmount: 0,
        remainAmount: 0
      });
      const applyList = reactive({ data: [] }); //申请记录数据
      const autoStoreList = reactive({ data: [] }); //授信申请详情
      const pageData = reactive({
        current: 1,
        pageSize: 10,
        total: 0
      });
      const storeId = ref('');
      const storeIdPath = ref('');

      const nextStep = (val) => {
        pageData.current = 1;
        pageData.total = 0;
        if (val == 'index') {
          step.value = 1;
          tabIndex.value = 0;
          getConmpany();
        } else {
          storeId.value = val;
          getCredit();
        }
      };

      const getCredit = () => { //查看授信纪录
        pageData.pageSize = 10;
        let param = {
          storeId: storeId.value,
          current: pageData.current,
          pageSize: pageData.pageSize
        }
        proxy
          .$get('v3/member/front/member/credit/creditList', param)
          .then(res => {
            if (res.state == 200) {
              autoStoreList.data = res.data.list;
              pageData.total = res.data.pagination.total;
              step.value = 2
            } else {
              ElMessage.error(res.msg);
            }
          })
      };

      const changeTab = (index) => {  //切换导航栏
        if (tabIndex.value != index) {
          tabIndex.value = index;
          pageData.current = 1;
          if (index == 0) {
            getConmpany();
          } else if (index == 1) {
            historyIndex.value = 0;
            getStoreList();
          } else {
            getApplyList();
          }
        }
      };

      const changeHisIndex = (index, id) => {
        if (historyIndex.value != index) {
          pageData.current = 1;
          pageData.total = 0;
          historyIndex.value = index;
          storeId.value = id;
          getPaylist(id);
          getSum(id);
        }
      };

      const getConmpany = () => { //获取授信申请数据
        pageData.pageSize = 6;
        let param = {
          current: pageData.current,
          pageSize: pageData.pageSize
        }
        proxy
          .$get('v3/member/front/member/credit/list', param)
          .then(res => {
            conmpany.data = res.data.list;
            pageData.total = res.data.pagination.total;
          })
      };
      const getStoreList = () => { //获取店铺列表
        proxy
          .$get('v3/seller/front/store/storeList', { type: 2 })
          .then(res => {
            storeList.data = res.data;
            if (storeList.data.length > 0) {
              if (storeIdPath.value) {
                storeId.value = storeIdPath.value
                storeList.data.forEach((item, index) => {
                  if (item.storeId == storeIdPath.value) {
                    historyIndex.value = index;
                  }
                })
                getPaylist(storeIdPath.value);
                getSum(storeIdPath.value);
                storeIdPath.value = '';
              } else {
                storeId.value = storeList.data[0].storeId
                getPaylist(storeList.data[0].storeId);
                getSum(storeList.data[0].storeId);
              }
            }
          })
      };
      const getSum = (id) => { //获取授信可用额度
        let param = {
          storeId: id
        }
        proxy
          .$get('v3/member/front/member/credit/getSum', param)
          .then(res => {
            storeList.creditAmount = res.data.creditAmount;
            storeList.remainAmount = res.data.remainAmount;
          })

      }
      const getPaylist = (id) => { //获取消费记录数据
        if (!id) { return; }
        pageData.pageSize = 10;
        let param = {
          storeId: id,
          current: pageData.current,
          pageSize: pageData.pageSize
        }
        proxy
          .$get('v3/member/front/member/credit/logList', param)
          .then(res => {
            storeList.list = res.data.list;
            pageData.total = res.data.pagination.total;
          })
      };
      const getApplyList = () => { //获取申请记录数据
        pageData.pageSize = 10;
        let param = {
          current: pageData.current,
          pageSize: pageData.pageSize
        }
        proxy
          .$get('v3/member/front/member/credit/applyList', param)
          .then(res => {
            applyList.data = res.data.list;
            pageData.total = res.data.pagination.total;
          })
      };

      const apply = (index, id, type) => { //申请授信
        let param = {
          storeId: id
        }
        proxy
          .$post('v3/member/front/member/credit/apply', param)
          .then(res => {
            if (res.state == 200) {
              ElMessage.success('申请已提交');
              if (type == 1) {
                conmpany.data[index].state = 2;
              } else {
                getApplyList();
              }
            } else {
              ElMessage.error(res.msg);
            }
          })
      };

      const toAuth = () => { //去认证
        router.push(`/member/authen`);
      };

      const navTo = (item) => {
        // 消费类型，1-下单支付，2-订单取消退回，3-退款，4-退货退款，5-信用账单支付
        if (item.type == 1 || item.type == 2) {
          router.push({
            path: '/member/order/info',
            query: {
              orderSn: item.logContent,
              permission: true
            }
          });
        } else if (item.type == 3 || item.type == 4) {
          router.push({
            path: '/member/order/refund/detail',
            query: {
              afsSn: item.logContent
            }
          });
        } else {
          router.push({
            path: '/member/credit/index',
            query: {
              keywords: item.logContent
            }
          });
        }
      };

      //页数改变
      const handleCurrentChange1 = current => {
        pageData.current = current;
        getConmpany();
      };
      const handleCurrentChange2 = current => {
        pageData.current = current;
        getPaylist(storeId.value);
      };
      const handleCurrentChange3 = current => {
        pageData.current = current;
        getApplyList();
      };
      const handleCurrentChange4 = current => {
        pageData.current = current;
        getCredit();
      };

      onMounted(() => {
        if (route.query.storeId) {
          storeIdPath.value = route.query.storeId;
          changeTab(1)
        } else {
          getConmpany();
        }
      })

      return {
        L,
        pageData,
        memberInfo,
        attestation,
        step,
        nextStep,
        tabIndex,
        changeTab,
        conmpany,
        historyIndex,
        changeHisIndex,
        getSum,
        getPaylist,
        storeList,
        getStoreList,
        applyList,
        getApplyList,
        autoStoreList,
        apply,
        toAuth,
        navTo,
        storeId,
        storeIdPath,
        getCredit,
        handleCurrentChange1,
        handleCurrentChange2,
        handleCurrentChange3,
        handleCurrentChange4,
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/style/finace/getPermission.scss";
</style>